import React from 'react';
import { Route, Routes } from 'react-router-dom';

import routes from './utils/routes';

import HomeIL from './pages/il-home/Home';
import FooterIL from './pages/il-home/sections/Footer';
import TopBar from './common/shared/TopBar';
import Home_US from './pages/us-home/Home'

const App = () => {

  const EngLayout = ({children}) => {
    return (
      <>
        <TopBar eng/>
        {children}
      </>
    )
  }

  const HebLayout = ({children}) => {
    return (
      <>
        <TopBar/>
        {children}
        <FooterIL/>
      </>
    )
  }

  const hebrewApp = (
    <Routes>
      <Route path={routes.home} element={
        <HebLayout>
          <HomeIL/>
        </HebLayout>
      }/>   
    </Routes>    
  )

  const englishApp = (
    <Routes>
      <Route path={routes.home}>
        <Route index element={
          <EngLayout>
            <Home_US/>
          </EngLayout>
        }/>               
      </Route>      
    </Routes>
  )

  const domain = window.location.hostname
  const search = window.location.search

  return (domain === 'www.wearecomets.com' || domain === 'wearecomets.com' || search === '?eng') ? englishApp : hebrewApp;
}

export default App;


