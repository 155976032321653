import { Link } from 'react-router-dom';
import IconButton from '../../../common/shared/IconButton'
import ImageSection from '../../../common/shared/layout/ImageSection'
const goTo = (path) => {
    window.location.href = path;
}
const header = {
    topic: 'סיורי שטח',
    title: <span style={{fontWeight: '200'}}>
        יוצאים 
        {" "}
        <span style={{fontWeight: '300'}}>
        להפלגת חקר חוויתית 
        </span>
        {" "}
        עם סיירת החוף.
    </span>,
    desc: <span>
    בשיתוף פעולה מסעיר עם 
    <Link to={'https://www.thecoastpatrol.org/'} target="_blank">
        <IconButton noBorder withLine inline>
            סיירת החוף
        </IconButton>
    </Link>
    , 
    יצאו חניכי שביטים אל הפלגת חקר חוויתית בים התיכון
    במהלכה יכירו את האקולוגיה הימית המקומית ויקחו חלק פעיל בפעילות איסוף נתונים.
    נתונים אלו ישמשו את התלמידים לצורך אימון מודלי בינה מלאכותית מתקדמים, כחלק מפרויקט הגמר שלהם.
    </span>
}

const image = 'https://cdn.prod.website-files.com/5ee1cfae4c443017764b608d/6617d85f87feb878fdc22d1b_1-p-800.jpg'

const Outdoor = () => {
    return (
        <ImageSection image={image} header={header}/>
    )
}

export default Outdoor
