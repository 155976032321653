export default {
    home: '/',
    contact: '/contact',
    expoProgram: '/interdisciplinary-program',
    expoSeminar: '/ai-for-earth',
    mlProgram: '/apollo',
    outdoorSeminar: '/saving-the-marine-life',
    workspace: '/workspace',
    datastore: '/datastore',
    imagineMe: '/imagine-me',
    hackathon: '/hackathon'
}