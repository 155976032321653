import AboutSection from '../../../components/AboutSection'

const About = ({customHeader}) => {

    const header = {
        topic: 'אודותינו',
        title: <span style={{fontWeight:'200'}}>
            הכירו את 
            {" "}
            <span style={{fontWeight:'300'}}>
                שביטים
            </span>
        </span>
    }

    return (
        <AboutSection header={customHeader ? customHeader : header}>
            שביטים הינה יוזמה חינוכית פורצת-דרך המפתחת ומפעילה תוכניות ייחודיות בנושא הבינה המלאכותית בסימן קיימות סביבתית. תוכניותנו הן ראשונות מסוגן בעולם ונבנו בקפידה על ידי מערך אנשי חינוך אקדמאיים ומובילים טכנולוגיים. אנו מזמינים את הלומדות לצאת למסע חינוכי מרתק אשר במהלכו יטפחו מחוייבות כלפי הסביבה, יועצמו כהוגות טכנולוגיה, יחשפו לרעיונות מעוררי השראה ויגלו את נבכי הטכנולוגיה של המחר.
            <br/><br/>
            למימוש החזון שלנו, אנו פועלים על פי מודל הוראה המדגיש ראייה הוליסטית, יישום מעשי, חופש יצירתיות והבנה מעמיקה של השלכות הטכנולוגיה על החברה והסביבה. בכך, אנו מבטיחים שלאורך מסען העתידי בנוף הטכנולוגי, ישארו התלמידות בתוך מסגרת ערכית התומכת בהיותם מובילי חדשנות חיובית ומודעת.
        </AboutSection>
    )
}

export default About