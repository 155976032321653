import AboutSection from '../../../components/AboutSection'

const GreenAI = ({customHeader}) => {

    const header = {
        topic: 'בינה מלאכותית וכדור-הארץ',
        title: <span style={{fontWeight:'200'}}>
        בינה מלאכותית
    {" "}
    <span style={{fontWeight:'300'}}>
    והגבולות הפלנטריים
    </span>
    </span>,
    }

    return (
        <AboutSection header={customHeader ? customHeader : header}>
בליבה של תוכנית הלימודים עומדת ההבנה העמוקה אודות השפעתה החיובית והשלילית של הבינה המלאכותית על  תשעת הגבולות הפלנטריים – המצפן האקולוגי שלנו. הקרקע, האוקיינוסים והאטמוספירה מהווים את מצע הקיום של המין האנושי בכדור הארץ, ולכן עלינו לשאוף לפיתוח פתרונות חכמים שאינם רק מקדמים את האנושות, אלא גם מגנים על הפלנטה שלנו. 
<br/><br/>
המשתתפות בתוכנית לומדות להמציא ולפתח תוך התחשבות בטביעת הרגל הפחמנית (Carbon Footprint) של כל פתרון בינה מלאכותית. זוהי הזדמנות ייחודית להיות חלק ממאמץ היוצר עתיד בו טכנולוגיה וקיימות הולכות יד ביד. 

        </AboutSection>
    )
}

export default GreenAI