import AboutSection from '../../../components/AboutSection'

const GreenAI = ({customHeader}) => {

    const header = {
        topic: 'AI & Earth',
        title: <span style={{fontWeight:'200'}}>
        Artificial Intelligence and 

    {" "}
    <span style={{fontWeight:'300'}}>
    Planetary Boundaries
    </span>
    </span>,
    }

    return (
        <AboutSection header={customHeader ? customHeader : header} eng>
Our curriculum centers on understanding AI's impact on the nine planetary boundaries - our ecological compass. Recognizing that land, oceans, and atmosphere are crucial for human existence, we develop AI solutions that advance humanity while protecting Earth. Students learn to innovate with a focus on minimizing the carbon footprint of AI technologies. This program offers a unique chance to shape a future where technology and sustainability coexist harmoniously, providing participants the opportunity to be part of an effort creating intelligent solutions that balance progress with planetary stewardship.
 

        </AboutSection>
    )
}

export default GreenAI