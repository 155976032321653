import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'השיטה החינוכית שלנו',
    title:  <span style={{fontWeight:'200'}}>
        דרך הוראה חדשנית שבליבה 
        {" "}
        <span style={{fontWeight:'300'}}>
        דיאלוג, עשייה וחוויה
        </span>
    </span>,
    desc: <span>
    דרכי הלמידה הייחודיות של שביטים מעודדות את התלמידות לצאת אל מסע נלהב של חקירה אישית וקבוצתית, יצירה חופשית והבעה אישית. יסודות אלו באים לידי ביטוי דרך דיונים מעשירים ופתוחים הנוגעים לתחומי דעת מגוונים, פעילויות משחקיות המעודדות למידה חברתית, והתנסות טכנולוגית מעשית התורמת להעצמתם של התלמידות כהוגי טכנולוגיה. 
    </span>
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1693775976/Eliran_Natan_A_group_of_cherfull_15_years_old_italian_elementar_5826e3d7-c7d5-4fbe-8720-5dd39891035d_ee5thm.png'

const EducationalMetodology = () => {
    return (
        <ImageSection image={image} header={header}/>
    )
}

export default EducationalMetodology
