import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'אחריות סביבתית',
    title: <span style={{fontWeight:'200'}}>
    מטפחים 
    {" "}
    <span style={{fontWeight:'300'}}>
    אחריות משותפת 
    </span>
    {" "}
    כלפי הסביבה.
    </span>,
    desc: <span>
    אנו מאמינים כי יש לפעול לקידום טכנולוגי הפועל בהרמוניה עם הסביבה ולמענה, ורואים חשיבות בקידום תפיסה חיובית של הבינה המלאכותית, וזאת בשל הפוטנציאל הגלום בה לשימור הסביבה. 
    <br/><br/>
    דרך הכרתן של המערכות האקולוגיות השונות, ההתעמקות עם האתגרים הסביבתיים, והעיסוק בפיתוח טכנולוגיה ירוקה, מסגלות התלמידות תחושת מחויבות כלפי כדור הארץ ומודעות לחשיבותה של טכנולוגיה ברת-קיימא, מכילה והוגנת.
    </span>
}


const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1695329490/0_0-3_sqcowq.png'

const Sustainability = () => {
    return (
        <ImageSection image={image} header={header} direction={"left"}/>
    )
}

export default Sustainability
