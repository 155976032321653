import { useViewport } from '../../../useViewport'
import styled from "styled-components/macro"
import ImageSection from '../../../common/shared/layout/ImageSection'
import SectionHeader from '../../../common/shared/layout/SectionHeader'
import ContactForm from '../../../components/ContactForm'
import SectionSpacer from '../../../common/shared/layout/SectionSpacer'

const header = {
    title: 'נשמח להיות בקשר!',
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1692483662/noamaller_Cheerfull_30_years_old_Israeli_-_looking_high_-_schoo_2d9f9521-c29c-466b-b006-4aa42fd049a5_pydnqy.png'

const ContactUs = () => {

    return (
        <ImageSection header={header} centerHeader image={image} direction={"left"}>
            <ContactForm/>
        </ImageSection>                 
    ) 
}

export default ContactUs