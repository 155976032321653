import AboutSection from '../../../components/AboutSection'

const ML = ({customHeader}) => {

    const header = {
        title: <span style={{fontWeight:'200'}}>
        Translating Real-World Challenges into 
    {" "}
    <span style={{fontWeight:'300'}}>
    Learning Problems
    </span>
    </span>,
    }

    return (
        <AboutSection eng header={customHeader ? customHeader : header}>
In the current technological era, scientists and engineers must embrace innovative thinking methodologies that allow them to examine scientific questions and engineering challenges through the lens of Machine Learning. This perspective is crucial for achieving breakthroughs in scientific, engineering, and societal domains confronting 21st-century humanity. 
<br></br><br/>
Throughout this program, we will focus on identifying and analyzing environmental challenges, characterizing these challenges as statistical learning problems, and developing intelligent systems to implement solutions. In this process, we will explore the complex relationships between systems and their environment, learn to design learning models that account for problem-specific constraints, and gain comprehensive knowledge of various learning algorithms, including their strengths and limitations.   

        </AboutSection>
    )
}

export default ML