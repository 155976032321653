import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    title: <span style={{fontWeight:'200'}}>
    מתבוננים בעולם דרך עדשת
    {" "}
    <span style={{fontWeight:'300'}}>
     הנתונים
     </span>
</span>,

    desc: <span>
בעידן ה- "בִּיג דָּאטָה" (Big Data) המתפתח במהירות, הנתונים הינם הלב הפועם של מערכות הבינה המלאכותית. מדע הנתונים (Data Science) הופך ליותר מדיסציפלינה טכנית; הוא מתגבש לכדי אמנות של גילוי תובנות. אנו מאמינים כי פיתוח פרספקטיבה חדשה מבוססת נתונים הינה המפתח המרכזי להובלת עולם הטכנולוגיה של המחר. 
<br/><br/>
במהלך התוכנית, נגלה שיטות מרכזיות לאיסוף, עיבוד והנדסת נתונים, ונבין כיצד לפענח תובנות שימושיות החבויות בנתונים. לצד העיסוק הטכני, נטפח הבנה הוליסטית של ההשלכות האתיות והסביבתיות הנלוות לפעילות איסוף הנתונים, ואף נעמוד על סכנת ההטייה (Bias) הנעוצה בהסתמכות עליהם. טיפוח נקודת מבט חדשה זו משלבת חשיבה ביקורתית, יצירתיות, אתיקה והבנה בינתחומית. 

    </span>
}


const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720003486/dainiusjasinevicius_a_camera_watching_tomatoes_growing_remote_m_3e2678ae-fe09-47b5-89db-0b91d51455b9_ncds6h.png'

const DataScience = () => {
    return (
        <ImageSection image={image} header={header} direction={'left'}/>
    )
}

export default DataScience
