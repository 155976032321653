import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: `בלב ליבו של המודל מצוי מוטיב הקיימות, אשר מטפח בקרב התלמידות תחושת אחריות אישית וחברתית כלפי כדור הארץ.`
}

const SustainabilitySlogen = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default SustainabilitySlogen