import Helmet from 'react-helmet'
import { spacing } from '../../common/style'
import SectionSpacer from '../../common/shared/layout/SectionSpacer'
import Page from '../../common/shared/layout/Page'
import IntroSlogen from './sections/IntroSlogen'
import HomeSlogen from './sections/HomeSlogen'
import IntroGallery from './sections/IntroGallery'
import ContactUs from './sections/ContactUs'
import EducationalMetodology from './sections/EducationalMetodology'
import Team from './sections/Team'
import Welcome from './sections/Welcome'
import About from './sections/About'
import SustainabilitySlogen from './sections/SustainabilitySlogen'
import AboutProjects from './sections/AboutProjects'
import Vision from './sections/Vision'
import EducationalModel from './sections/EducationalModel'
import Sustainability from './sections/Sustainability'
import Outdoor from './sections/Outdoor'
import { useViewport } from '../../useViewport'
import Container from '../../common/shared/layout/Container'
import SingleDisplay from '../../common/shared/layout/SingleDisplay'
import Spacer from '../../common/shared/layout/Spacer'
import FocusSection from '../../common/shared/layout/FocusSection'
import Theory from './sections/Theory'
import LearningProblems from './sections/LearningProblems'
import DataScience from './sections/DataScience'
import Coding from './sections/Coding'
import GreenAI from './sections/GreenAI'

const Home = () => {

    const { device } = useViewport()

    return (
        <Page>
            <Helmet>
                <title>שביטים - בינה מלאכותית בסימן קיימות</title>
                <meta name="description" content="צאו אל מסע חינוכי מרתק בנושא הבינה המלאכותית בסימן קיימות סביבתית."/>
            </Helmet>
            <Welcome/>
                {
                    (device === 'mobile' || device == 'tablet') && (
                        <Container id="general">
                            <About customHeader={{}}/>
                            <SectionSpacer/>
                            <IntroGallery/>
                            <SectionSpacer/>
                        </Container>
                    )
                }
                {
                    (device === 'desktop') && (
                        <Container  center width={12}>
                            <SectionSpacer id="general"/>
                            <IntroSlogen/>
                            <SectionSpacer/>
                            <IntroGallery/>
                            <SectionSpacer/>
                            <About/>
                            <SectionSpacer/>
                        </Container>
                    )
                } 
            <HomeSlogen/>
            <SectionSpacer id="featuers"/>
            <Vision/>
            <SectionSpacer/>
            <EducationalModel/>
            <SectionSpacer/>
            <EducationalMetodology/>
            <SectionSpacer/>
            <SustainabilitySlogen/>
            <SectionSpacer/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719817174/elirannatan_photo_of_a_human_spotted_in_the_middle_of_the_ocean_55efd23a-4591-4714-ba1d-d4f99f985496_sfstzr.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1718866650/Group_45_wn00xw.png'}
                caption={'מגלים כלי שיט בלב הסערה: כחלק מפרויקט הגמר שלהן, תלמידות שביטים פיתחו את StormSight, מודל עיבוד תמונה מתקדם מבוסס למידה עמוקה, המיומן באיתור כלי שיט קטנים בצילומי אוויר. '}/>
            <Spacer height={spacing.spacing20}/>
            <AboutProjects/>
            <SectionSpacer id="content"/>
            <FocusSection header={{
                topic: 'תוכנית הלימודים',
                title: 'תוכנית לימודים עבור המאה ה- 21',
                desc: 'תכנית הלימודים המתקדמת של שביטים מתוכננת להקנות לחניכות את המיומנויות הנדרשות על מנת לבטא אתגרים מדעיים ובעיות הנדסיות כבעיות של למידת מכונה ולתכנן עבורם פתרונות בינה מלאכותית. אנו מעניקים  דגש להבנה התיאורטית של הבינה המלאכותית לצד הכרת שיטות מרכזיות במדע הנתונים ואלגוריתמים של למידת מכונה.'
            }}/>
            <Theory/>
            <SectionSpacer/>
            <LearningProblems/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719687720/image_7_stxo0g.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719866824/image_7_stxo0g_c_crop_ar_1_1_dip4eg.png'}
                width={9}/>
            <SectionSpacer/>
            <DataScience/>
            <SectionSpacer/>
            <Coding/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007106/Group_16_1_mqurji.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1720007204/Group_16_1_mqurji_c_crop_ar_1_1_ypeyx1.png'}
                width={9}/>        
            <SectionSpacer id="sustain"/>
            <FocusSection header={{
                topic: 'קיימות ושטח',
                title: 'מתחברים מחדש לסביבה',
                desc: 'בשביטים, הבינה המלאכותית לעולם אינה נלמדת בריק, אלא תמיד מתוך הקשר אל העולם האמיתי בו הינה פועלת, וזאת מתוך התובנה לפיה טכנולוגיה זו נסמכת על הסביבה באופן הדוק ופועלת באופן הוליסטי בתוכה. גישה זו מניעה אותנו אל מעבר למעבדת המחשבים, לעיסוק וחקר פעיל במדבר, בים ובשדה. '
            }}/>
            <Sustainability/>
            <SectionSpacer/>
            <Outdoor/>
            <SectionSpacer/>
            <GreenAI/>
            <Spacer height={spacing.spacing20}/>
            <SingleDisplay 
                image={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719686733/elirannatan_Satellite_photography_of_wind_turbines_farm_--ar_16_b590efb9-3253-4b22-ae77-104502ea0645_afyr24.png'} 
                imageMobile={'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719912298/elirannatan_Satellite_photography_of_wind_turbines_farm_--ar_16_b590efb9-3253-4b22-ae77-104502ea0645_afyr24_c_crop_ar_1_1_lwph9i.png'}
                width={9}/>
            <SectionSpacer id="team"/>
            <Team/>
            <SectionSpacer id="contact"/>
            <ContactUs/>
        </Page>  
    )
}

export default Home