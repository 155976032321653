import AboutSection from '../../../components/AboutSection'

const Coding = ({customHeader}) => {

    const header = {
        title: <span style={{fontWeight:'200'}}>
        מפתחים אלגוריתמי בינה מלאכותית
    {" "}
    <span style={{fontWeight:'300'}}>
    בפייתון
    </span>
    </span>,
    }

    return (
        <AboutSection header={customHeader ? customHeader : header} >
למידה עמוקה (Deep Learning) הינה הכוח המניע של מהפכת הטכנולוגיה הבאה. החל מזיהוי עצמים (Object Detection) בתמונות לווין, דרך חיזוי מזג האוויר ועד לפיתוח רובוטים אוטונומיים, אלגוריתמי למידה עמוקה מגדירים מחדש את האופן בו אנו מתקשרים עם העולם. שפת התכנות פייתון (Python), בזכות פשטותה ועוצמתה, הפכה לשפת הנבחרת עבור מדעני נתונים ומהנדסי בינה מלאכותית בכל העולם.
<br/><br/>
במהלך התוכנית, יפתחו התלמידים הבנה מעמיקה של האלגוריתמים המרכזיים ויישמו אותם באמצעות שפת התכנות פייתון לצורך פתרון בעיות אקולוגיות מורכבות. במקביל, ירכשו המשתתפים הבנה מוצקה של עקרונות מדעי המחשב והנדסת תוכנה בהקשר של פיתוח בינה מלאכותית.  

        </AboutSection>
    )
}

export default Coding