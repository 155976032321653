import Gallery from '../../../components/Gallery'

const images = [
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719679521/image_38_j0qyob_a_hflip_uakpj1.png',
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1698184934/elirannatan_Agriculture_AI_-_driven_robotic_systems_could_trans_1b7af9c5-6811-4f8e-92d1-1fccfbca809b_q6zrzo.png'
]

const IntroGallery = () => {
    return (
        <Gallery images={images} twoOnMobile/>
    )
}

export default IntroGallery