import AboutSection from '../../../components/AboutSection'

const AboutProjects = ({customHeader}) => {

    const header = {
        title: <span style={{fontWeight:'200'}}>
            מגלים את העולם האמיתי דרך
            {" "}
            <span style={{fontWeight:'300'}}>
             אתגרים מסעירים
             </span>
        </span>
    }

    return (
        <AboutSection header={customHeader ? customHeader : header}>
החל מניבוי כיוון התפשטותן של שריפות ועד לזיהוי כלי שיט בלב ים סוער, אנו נחשף למגוון בעיות קיימות מהעולם האמיתי, נהגה פתרונות יצירתיים ונגלה כיצד לרתום את כוחה של הבינה המלאכותית למימוש רעיונותינו. לשם כך, נעבוד בקבוצות ״סטארטאפ״ קטנות, במסגרתן נעסוק בחקר הכולל איסוף וניתוח נתונים ופיתוח אלגוריתמי למידת מכונה (Machine Learning). דרך מסע זה,  נלמד להכיר את אתגרי הקיימות הסביבתית והחברתית, נטפח יכולות של עבודה בצוות ובעיקר – נגלה כיצד ניתן להוביל שינוי חיובי בעולם באמצעות בינה מלאכותית. 

        </AboutSection>
    )
}

export default AboutProjects