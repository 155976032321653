import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: 'דרכי הלמידה הייחודיות של שביטים מעודדות את התלמידות לצאת אל מסע נלהב של חקירה אישית וקבוצתית, יצירה חופשית והבעה אישית.'
}

const HomeSlogen = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default HomeSlogen