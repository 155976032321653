import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'המודל החינוכי שלנו',
    title:  <span style={{fontWeight:'200'}}>
        <span style={{fontWeight:'300'}}>
        למידה בינתחומית 
        </span>
        {" "}
        המדגישה ישום מעשי וחופש יצירתיות.
    </span>,
    desc: <span>
        למימוש החזון שלנו, אנו פועלים על פי מודל הוראה המדגיש ראייה הוליסטית, יישום מעשי, חופש יצירתיות והבנה מעמיקה של השלכות הטכנולוגיה על החברה והסביבה. בכך, אנו מבטיחים שלאורך מסעם העתידי בנוף הטכנולוגי, 
        {" "}
        <span style={{fontWeight:600}}>
        תשארנה התלמידות בתוך מסגרת ערכית התומכת בהיותן מובילות חדשנות חיובית ומודעת.
        </span>
    <br/><br/>
    החל ממדעי המחשב דרך פילוסופיה ועד למדעי כדור הארץ - המודל החינוכי שלנו מיישם הוראה בינתחומית החושפת את התלמידות למגוון תחומי דעת אשר אינם נלמדים בתוכנית הלימודים הרגילה. 
    </span>
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719731439/elirannatan_cute_cheerful_female_high-school_student_wearing__80578dc7-e2e9-4199-813d-72cbf3bdf0d8_2_hveozy.png'

const EducationalModel = () => {
    return (
        <ImageSection image={image} header={header} direction={"left"} filter='brightness(1.2)'/>
    )
}

export default EducationalModel
