import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    title: <span style={{fontWeight:'200'}}>
        מבינים את 
    {" "}
    <span style={{fontWeight:'300'}}>
    הטבע
    </span>
    {" "}
    של הבינה.
    </span>,
    desc: <span>
אנו מאמינים כי הבנה עמוקה של הבינה המלאכותית מתחילה בחקירה יסודית של הבינה הטבעית. החל מהדינמיקה המורכבת בין לווייתנים ועד לפעולתה של מושבת נמלים, הבנת ההתנהגות של בעלי החיים מעניקה לתלמידות תובנות חשובות לגבי האופן בו מערכות מסוגלות ללמוד ולהסתגל.
    <br/><br/>
    הנחת יסודות אלו תעניק לתלמידות רקע תאורטי המשתמש בסיס איתן להבנת רעיונות ושיטות של למידת מכונה (Machine Learning) – אבן הפינה של הבינה המלאכותית המודרנית. 

    </span>
}


const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1697838113/0_3-4_jjwbnw.png'

const Theory = () => {
    return (
        <ImageSection image={image} header={header}/>
    )
}

export default Theory
