import ImageSection from '../../../common/shared/layout/ImageSection'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1697838113/0_3-4_jjwbnw.png'

const InterApproch = () => {
    const header = {
        
        title: <span style={{fontWeight:200}}>
           Understanding Intelligence at its {" "}
           <span style={{fontWeight:300}}>Core</span>
        </span>,
        desc: <span>
            We believe that the richest understanding of AI begins with a deep exploration of natural intelligence. From whale communication to complex ant colony dynamics, studying animal behavior offers profound insights into how creatures adapt and learn from their surroundings, as they demonstrate how random events can systematically evolve into innovative solutions through experimentation and adaptation.
            <br/><br/>
            By establishing these foundational concepts, students gain a robust theoretical framework essential for comprehending machine learning principles and techniques
        </span>        
    }
    
    return (
        <ImageSection eng={true} image={image} header={header} direction={"left"}/>
    )
}

export default InterApproch
