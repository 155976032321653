import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'Sustainability',
    title: <span style={{fontWeight:'200'}}>
Fostering  
    {" "}
    <span style={{fontWeight:'300'}}>
    shared responsibility
    </span>
    {" "}
    towards the environment
    </span>,
    desc: <span>
We champion technological progress that works in harmony with nature, promoting a positive view of AI for its environmental conservation potential. Through hands-on exploration of ecosystems, environmental challenges, and green tech development, students cultivate a deep commitment to Earth and an understanding of sustainable, inclusive technology. This approach instills environmental stewardship while harnessing AI's power for positive change, fostering a generation of innovators who balance technological advancement with ecological responsibility.
    </span>
}


const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1695329490/0_0-3_sqcowq.png'

const Sustainability2 = () => {
    return (
        <ImageSection image={image} header={header} direction={"left"} eng/>
    )
}

export default Sustainability2
