import ImageSection from '../../../common/shared/layout/ImageSection'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719846668/elirannatan_3d_model_minimal_wireframes_on_a_black_white_ai_vis_c1914ce0-620d-46f8-8684-ff57791e4762_znvrw1.png'

const ProblemBased = () => {
    const header = {
        
        title: <span style={{fontWeight:'200'}}>
           Understanding the World Through the {" "}
           <span style={{fontWeight:'300'}}>
           Data Lens
           </span>
        </span>,
        desc: <span>
In the rapidly evolving Big Data era, data has become the lifeblood of AI systems. We believe that cultivating a novel, data-driven perspective is crucial for spearheading the technological landscape of tomorrow. 
<br/><br/>
Throughout this program, we will delve into essential methodologies for data collection, processing, and engineering, while learning to extract valuable insights hidden within vast datasets. Beyond the technical aspects, we will foster a comprehensive understanding of the ethical and environmental ramifications of data collection practices, and confront the inherent risks of bias in data dependence. 
        </span>        
    }
    
    return (
        <ImageSection eng={true} image={image} header={header}/>
    )
}

export default ProblemBased
