import ImageSection from '../../../common/shared/layout/ImageSection'
import DiscoverButton from '../../../components/DiscoverButton'
import { GradiantText } from '../../../common/typography'
import { color, spacing } from '../../../common/style'
import { HashLink } from 'react-router-hash-link'
import Spacer from '../../../common/shared/layout/Spacer'
import { useViewport } from '../../../useViewport'

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1693578452/Eliran_Natan_A_10_year_old_israeli_female_brown_hair_and_wearin_e7576478-2cc5-4a7d-b290-cad63e6f41db_htjsdg.png'

const Welcome = () => {
    const { device } = useViewport()

    const header = {
        title: <span>
        <GradiantText color={color.blackGradient} style={{fontWeight:'100', fontSize: device === 'desktop' ? '56px' : '48px'}}>
         סיפורנו מתחיל 
         {" "}
         <span style={{
            fontWeight:'300'
         }}>
            בעתיד.
         </span>
        </GradiantText>
        </span>,
        desc: device === 'mobile' || device === 'tablet' ? null : `
    גלו את שביטים:
      יוזמה חינוכית פורצת-דרך המפתחת ומפעילה תוכניות ייחודיות בנושא הבינה המלאכותית בסימן קיימות סביבתית.
    
         `,
         girly: true
    }

    return (
        <ImageSection image={image} header={header} first>
            {(device === 'desktop') && (
                <>
                <Spacer height={spacing.spacing6}/>
                <DiscoverButton/>
                </>
            )}
        </ImageSection>
    )
}

export default Welcome
