import AboutSection from '../../../components/AboutSection'

const LearningProblems = ({customHeader}) => {

    const header = {
        title: <span style={{fontWeight:'200'}}>
        מנסחים אתגרים
    {" "}
    <span style={{fontWeight:'300'}}>
    כבעיות של למידת מכונה
    </span>
    </span>,
    }

    return (
        <AboutSection header={customHeader ? customHeader : header}>
בעידן הטכנולוגיה החדש, מדענים ומהנדסים נדרשים לאמץ מתודולוגיות חשיבה חדשניות בעזרתן ניתן לבחון שאלות מדעיות ואתגרים הנדסיים דרך העדשה של למידת מכונה (Machine Learning). פרספקטיבה זו הינה קריטית להשגת פריצות דרך בתחומי המדעים,  ההנדסה והחברה העומדים בפני האנושות של המאה ה-21.  
<br/><br/>
במהלך התוכנית, נעסוק בהכרת אתגרים סביבתיים, ניתוחם ואפיונם כבעיות של למידה סטטיסטית. בתהליך אפיון זה, כמו גם במהלך פיתוח מערכת נבונה למימוש הפתרון, נעמוד על הבנת היחסים המורכבים החלים בין המערכת לבין העולם, נלמד כיצד לאפיין מודלי למידה המתחשבים באילוצי הבעיה, ונכיר לעומק את אלגוריתמי הלמידה השונים על יתרונותיהם וחסרונותיהם.   

        </AboutSection>
    )
}

export default LearningProblems