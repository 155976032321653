import React from 'react'
import styled from 'styled-components/macro'
import Spacer from './layout/Spacer'
import { spacing } from '../style'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
            width: 28px;
        }
    }
`

const Social = ({mobile, eng}) => {
    return (
        <Wrapper mobile={mobile}>
                <a href={eng ? "https://www.instagram.com/cometseducation/" : "https://www.instagram.com/shavitimprograms/"} target="_blank">
                    <img src={"/shavitim-assets/social-instegram.png"}/>
                </a>
                <Spacer width={spacing.spacing3}/>
                <a href={eng ? "https://www.facebook.com/profile.php?id=61552927301529" : "https://www.facebook.com/profile.php?id=100092663877340"} target="_blank">
                    <img src={"/shavitim-assets/social-facebook.png"}/>
                </a>
        </Wrapper>        
    )
}

export default Social