import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: `שביטים מגלה לתלמידות ותלמידים מצטיינים אל העולם המורכב והמרתק של הבינה המלאכותית מבעד לראי של קיימות סביבתית.`
}

const IntroSlogen = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default IntroSlogen