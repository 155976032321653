import { Link } from 'react-router-dom';
import IconButton from '../../../common/shared/IconButton'
import ImageSection from '../../../common/shared/layout/ImageSection'
const goTo = (path) => {
    window.location.href = path;
}
const header = {
    topic: 'Outdoor Activities',
    title: <span style={{fontWeight: '200'}}>
        Embarking on an Experiential 
        {" "}
        <span style={{fontWeight: '300'}}>
        Research Voyage
        </span>
    </span>,
    desc: <span>
    In an exciting collaboration with the Coastal Patrol, Comets participants will set sail on an immersive research expedition in the Sea. During this voyage, they will explore local marine ecology firsthand and actively participate in data collection activities. These collected data will be used by the students to train advanced artificial intelligence models as part of their final projects. This hands-on experience allows participants to apply their theoretical knowledge to real-world marine conservation challenges, bridging the gap between classroom learning and practical AI development.
    </span>
}

const image = 'https://cdn.prod.website-files.com/5ee1cfae4c443017764b608d/6617d85f87feb878fdc22d1b_1-p-800.jpg'

const Outdoor = () => {
    return (
        <ImageSection image={image} header={header} eng/>
    )
}

export default Outdoor
