import ImageSection from '../../../common/shared/layout/ImageSection'
import { GradiantText } from '../../../common/typography'
import { color } from '../../../common/style'
import { useViewport } from '../../../useViewport'

const header = {
    topic: 'החזון שלנו',
    title:  <span style={{fontWeight:'200'}}>
        מדליקים את ניצוץ 
        {" "}
        <span style={{fontWeight:'300'}}>
        הסקרנות והחדשנות
        </span>
    </span>,
    desc: <span>
    תכליתנו הינה להדליק את הניצוץ לחקר ולחדשנות בקרב התלמידות הסקרניות, לטפח בהן את תחושת המחויבות כלפי הסביבה ולחשוף אותן אל תפקידה המכריע של הבינה המלאכותית בשימור הסביבה, ובכך, להעצים את התלמידות כהוגות בעלות חזון לעתיד בר קיימא.
    <br/><br/>
    אנו דוגלים בקידום טכנולוגי אחראי, טיפוח תפיסה חיובית ומודעת כלפי הטכנולוגיה, ופיתוח ראייה הולסטית של טבע וטכנולוגיה.
    </span>
}

const Vision = () => {
    const { device } = useViewport()
    const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1718969434/Eliran_Natan_A_cheerful_15_year_old_american_black_female_wea_a64b5382-1cc8-4f4e-8223-29b6253fea82_3_ugxnyh.png'
    return (
        <ImageSection image={image} header={header}/>
    )
}

export default Vision
