import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'Educational Approach',
title: <span style={{fontWeight:'200'}}>
    Innovate, Create, 
    {" "}
    <span style={{fontWeight:'300'}}>
    Learn.
    </span>
    </span>,
    desc: <span>
    At Comets, we embrace a dynamic, learner-centered approach that blends theory with practice. Our methodology is rooted in project-based learning, where students tackle real-world AI challenges, fostering critical thinking and problem-solving skills. We emphasize collaborative work, mirroring industry practices, and encourage interdisciplinary connections to deepen understanding. 
    <br/><br/>
    Our curriculum adapts to emerging technologies, ensuring students stay at the cutting edge. We integrate ethical considerations throughout, preparing students to navigate the complex implications of AI. 
    </span>
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1709109839/image_37_ytyodi.png'

const EducationalApproach = () => {
    return (
        <ImageSection image={image} header={header} eng={true}/>
    )
}

export default EducationalApproach
