import AboutSection from '../../../components/AboutSection'

const AboutProjects = ({customHeader}) => {

    const header = {
        title: <span style={{fontWeight:'200'}}>
            Exploring the 
            {" "}
            <span style={{fontWeight:'300'}}>
            real world
             </span>
            {" "}
            through thrilling challenges
        </span>
    }

    return (
        <AboutSection eng header={customHeader ? customHeader : header}>
From forecasting wildfire spread to detecting ships in turbulent seas, students tackle diverse real-world challenges. They craft innovative solutions, harnessing artificial intelligence to bring their ideas to life. Working in small 'startup' teams, students conduct research, analyze data, and develop machine learning algorithms. 
<br></br><br></br>
This journey exposes them to environmental and social sustainability issues, hones their collaboration skills, and—most critically—reveals how they can drive positive global change through AI. Students don't just learn about technology; they use it to shape a better future.

        </AboutSection>
    )
}

export default AboutProjects