import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'Our Vision',
    title: <span style={{fontWeight:'200'}}>
        Empowering Education for an 
        {" "}
        <span style={{fontWeight:'300'}}>
        AI-Driven Future
        </span>
    </span>,
    desc: <span>
    At Comets, we envision a world where every student is empowered to harness the transformative potential of artificial intelligence. To realize this vision, we're committed to equipping educators with everything they need to successfully deploy world-class AI programs across their institutions. 
    <br/><br/>
    By empowering teachers with cutting-edge resources, comprehensive training, and ongoing support, we aim to cultivate a global community of learners who not only adapt to rapid technological change but drive it.
    </span>
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1697836427/elirannatan_A_cheerful_15_year_old_american_asian_teenage_boy_m_e3385d99-d824-4ac7-8971-cb84ac4d8107_m6rbh3.png'

const Vision = () => {
    return (
        <ImageSection image={image} header={header} eng={true}/>
    )
}

export default Vision
