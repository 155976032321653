import AboutSection from '../../../components/AboutSection'

const Coding = ({customHeader}) => {
    const header = {
        title: <span style={{fontWeight:'200'}}>
Developing AI algorithms with
    {" "}
    <span style={{fontWeight:'300'}}>
    Python
    </span>
    </span>,
    }


    return (
        <AboutSection header={customHeader ? customHeader : header} eng>
Deep Learning is the driving force of the next technological revolution. From object detection in satellite images, through weather forecasting, to the development of autonomous robots, deep learning algorithms are redefining how we interact with the world. The Python programming language, thanks to its simplicity and power, has become the language of choice for data scientists and artificial intelligence engineers worldwide.
<br/><br/>
During the program, students will develop a deep understanding of the core algorithms and implement them using the Python programming language to solve complex ecological problems. In parallel, participants will acquire a solid understanding of computer science principles and software engineering in the context of artificial intelligence development. 

        </AboutSection>
    )
}

export default Coding