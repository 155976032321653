import SlogenSection from '../../../common/shared/layout/SlogenSection'

const content = {
    slogen: `Comets delivers world-class AI programs, revolutionizing learning and inspiring the next generation of innovators.`
}

const IntroSlogen = () => {
    return (
        <SlogenSection>
            {content.slogen}
        </SlogenSection>
    )
}

export default IntroSlogen