import Gallery from '../../../components/Gallery'

const images = [
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1709110316/image_38_j0qyob.png',
    'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1695905558/elirannatan_private_tour_to_a_bee_farm_for_small_group_of_cheer_9d116da6-331d-4be7-aa28-79abc57166d1_hbhubw.png'
]

const IntroGallery = () => {
    return (
        <Gallery images={images} twoOnMobile/>
    )
}

export default IntroGallery