import ImageSection from '../../../common/shared/layout/ImageSection'
import DiscoverButton from '../../../components/DiscoverButton'
import { GradiantText } from '../../../common/typography'
import { color, spacing } from '../../../common/style'
import { HashLink } from 'react-router-hash-link'
import Spacer from '../../../common/shared/layout/Spacer'
import { useViewport } from '../../../useViewport'



const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1699435282/elirannatan_A_13_year_old_american_korean_student_wearing_black_fc4b7b83-58f4-4480-a6d2-cb874b754aa9_v49avg.png'

const Welcome = () => {

    const { device } = useViewport()

    const header = {
        title: <span>
        <GradiantText color={color.blackGradient} style={{fontWeight:'100', fontSize: (device === 'desktop') ? '56px' : '48px'}}>
            Our Story Unfolds in the <span style={{fontWeight:'300'}}>Future</span>
         </GradiantText>
        </span>,
        desc:( device === 'mobile' || device === 'tablet') ? null : `
            Comets is a groundbreaking initiative at the forefront of educational innovation, dedicated to developing globally unique AI programs.
         `,
         girly: true
    }

    return (
        <ImageSection image={image} header={header} first eng>
            {(device === 'desktop') && (
                <>
                <Spacer height={spacing.spacing6}/>
                <DiscoverButton eng/>
                </>
            )}
        </ImageSection>
    )
}

export default Welcome
