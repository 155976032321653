import ImageSection from '../../../common/shared/layout/ImageSection'

const header = {
    topic: 'Educational Model',
    title: <span style={{fontWeight:'200'}}>
    Hands-On Innovation:
    {" "}
    <span style={{fontWeight:'300'}}>
    The Comets Way.
    </span>
    </span>,
    desc: <span>
    Our educational model is built on the foundation of experiential learning and cutting-edge AI technology. We combine hands-on projects, real-world problem-solving, and collaborative learning experiences to engage students in the practical applications of AI. 
    <br/><br/>
    Our curriculum is designed to be adaptive and interdisciplinary, integrating AI concepts across various subjects to provide a holistic understanding. By emphasizing critical thinking, creativity, and ethical considerations, we prepare students not just to use AI tools, but to innovate and lead in an AI-driven world.
    </span>
}

const image = 'https://res.cloudinary.com/dfdk4g2pj/image/upload/v1719731439/elirannatan_cute_cheerful_female_high-school_student_wearing__80578dc7-e2e9-4199-813d-72cbf3bdf0d8_2_hveozy.png'

const EducationalModel = () => {
    return (
        <ImageSection image={image} header={header} direction={"left"} eng={true}/>
    )
}

export default EducationalModel
